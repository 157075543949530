import React, { useState, useRef, useEffect } from "react";
import { comparedPlans } from "./PackageData";
import { PackageCard } from "./PackageCard";

const Package = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartXRef = useRef(0);
  const touchEndXRef = useRef(0);
  const autoMoveInterval = useRef(null);

  const handleTouchStart = (e) => {
    touchStartXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndXRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartXRef.current - touchEndXRef.current > 50) {
      nextSlide();
    }

    if (touchStartXRef.current - touchEndXRef.current < -50) {
      prevSlide();
    }
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === comparedPlans.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? comparedPlans.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    autoMoveInterval.current = setInterval(nextSlide, 3000); // Auto-move every 3 seconds

    return () => clearInterval(autoMoveInterval.current);
  }, []);

  const handleMouseEnter = () => {
    clearInterval(autoMoveInterval.current);
  };

  const handleMouseLeave = () => {
    autoMoveInterval.current = setInterval(nextSlide, 3000);
  };

  return (
    <div className="w-full h-full pt-20" data-aos="fade-up">
      {/* Grid Layout for larger screens */}
      <div className="grid-container">
        {comparedPlans.map((plan, index) => (
          <PackageCard key={index} plan={plan} elevated={index === 1} />
        ))}
      </div>

      {/* Carousel for smaller screens */}
      <div
        className="carousel-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="carousel-wrapper"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {comparedPlans.map((plan, index) => (
            <div className="carousel-slide" key={index}>
              <PackageCard plan={plan} elevated={index === 1} />
            </div>
          ))}
        </div>
        <div className="carousel-indicators">
          {comparedPlans.map((_, index) => (
            <div
              key={index}
              className={`carousel-indicator ${
                index === currentIndex ? "active" : ""
              }`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Package;
