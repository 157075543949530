import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import app from "../../config/firease";
import { LuDot } from "react-icons/lu";
import { FaRegCopy } from "react-icons/fa";
import ShimmerBlogSection from "../../Shimmer/ShimmerBlogSection";

const BlogSingle = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [latestPosts, setLatestPosts] = useState([]);
  const [openShare, setOpenShare] = useState(null);
  const [copiedMessage, setCopiedMessage] = useState(false); // State for "Copied!" message
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const fetchBlog = async () => {
      const db = getFirestore(app);
      const blogRef = doc(db, "posts", id); // Reference to the blog document
      try {
        const blogDoc = await getDoc(blogRef);
        if (blogDoc.exists()) {
          setBlog({ id: blogDoc.id, ...blogDoc.data() }); // Set the blog data
        } else {
          setError("Blog not found");
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
        setError("Error fetching blog");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  useEffect(() => {
    const fetchLatestPosts = async () => {
      const db = getFirestore(app);
      const latestPostsQuery = query(
        collection(db, "posts"),
        orderBy("date", "desc"),
        limit(4)
      );
      try {
        const querySnapshot = await getDocs(latestPostsQuery);
        const posts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLatestPosts(posts);
      } catch (error) {
        console.error("Error fetching latest posts:", error);
      }
    };

    fetchLatestPosts();
  }, []);

  if (loading) {
    return (
      <p>
        <ShimmerBlogSection />
      </p>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!blog) {
    return <p>Blog not found</p>;
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const handleShareClick = (id) => {
    setOpenShare(openShare === id ? null : id); // Toggle share menu
  };

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      setCopiedMessage(true);
      setTimeout(() => setCopiedMessage(false), 600); // Hide the message after 1 minute
    });
  };

  return (
    <div className="px-28 blogpage-px pb-20">
      <div className="flex flex-col gap-40 text-wrap font-montserrat">
        <div>
          <div>
            <div className="flex flex-col lg:flex-row">
              <div className="p-6 my-20 lg:w-3/4">
                <div className="w-full bg-[#25a186] text-white h-40 flex flex-col gap-2 justify-center items-start lg:pl-20 pl-4 mb-10">
                  <h2 className="text-2xl font-bold font-montserrat">
                    {blog.title}
                  </h2>
                  <span className="text-xs">{formatDate(blog.date)}</span>
                  <div className=" flex justify-between relative">
                    <div className="py-2 z-10 flex gap-2 items-center">
                      <FacebookShareButton url={window.location.href}>
                        <FacebookIcon size={30} round />
                      </FacebookShareButton>
                      <WhatsappShareButton url={window.location.href}>
                        <WhatsappIcon size={30} round />
                      </WhatsappShareButton>
                      <LinkedinShareButton url={window.location.href}>
                        <LinkedinIcon size={30} round />
                      </LinkedinShareButton>
                      <TwitterShareButton url={window.location.href}>
                        <TwitterIcon size={30} round />
                      </TwitterShareButton>
                      <button
                        onClick={() => handleCopyLink(window.location.href)}
                        className="flex items-center justify-center w-8 h-8 text-white bg-gray-500 rounded-full hover:bg-gray-700"
                      >
                        <FaRegCopy />
                      </button>
                      {copiedMessage && (
                        <div className="absolute top-0 right-0 bg-green-500 text-white text-xs p-1 rounded">
                          Copied!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <article className="flex flex-col gap-4">
                  <div className="">
                    {blog.imageUrl && (
                      <img
                        src={blog.imageUrl}
                        alt={blog.title}
                        className="object-cover w-full lg:w-[50%]"
                      />
                    )}
                  </div>
                  <div className="flex flex-col gap-4">
                    <h6>
                      <a href="/blogs" className="text-orange-500 underline">
                        {blog.categoryName}
                      </a>
                    </h6>

                    <div className="">
                      <div className="">
                        <h2 className="text-2xl font-bold font-outfit">
                          {blog.title}
                        </h2>
                        <div className="flex gap-1 text-start">
                          <span className=" text-xs text-orange-500 italic">
                            admin
                          </span>
                          <span>
                            <LuDot />
                          </span>
                          <span className="text-xs">
                            {formatDate(blog.date)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className=""
                    dangerouslySetInnerHTML={{ __html: blog.description }}
                  />
                  <p className="font-satoshi leading-relaxed">
                    <span className="font-semibold ">Disclaimer </span>: This
                    blog post was written to help you to make healthy and better
                    food choices altogether. So, be aware and take care. The
                    important thing to consider is your own health before
                    starting a diet that is restrictive. Always seek advice from
                    a doctor/dietitian before starting if you have any concerns.{" "}
                    <br />
                    <br />
                    Eat Healthy, Live Healthy. Enjoy a long happy life.
                  </p>
                </article>
                {/* ---------------- share buttons------------------------- */}
                <div className="flex lg:flex-row flex-col gap-4 lg:gap-0 lg:justify-between items-center pt-10">
                  <Link to="/blogs">
                    <button className="bg-[#2AAA8A] text-white px-4 py-2 rounded hover:bg-[#478778] transition-colors">
                      More Blogs
                    </button>
                  </Link>
                  <div className=" flex justify-between relative">
                    <div className="py-2 z-10 flex gap-2 items-center">
                      <FacebookShareButton url={window.location.href}>
                        <FacebookIcon size={30} round />
                      </FacebookShareButton>
                      <WhatsappShareButton url={window.location.href}>
                        <WhatsappIcon size={30} round />
                      </WhatsappShareButton>
                      <LinkedinShareButton url={window.location.href}>
                        <LinkedinIcon size={30} round />
                      </LinkedinShareButton>
                      <TwitterShareButton url={window.location.href}>
                        <TwitterIcon size={30} round />
                      </TwitterShareButton>
                      <button
                        onClick={() => handleCopyLink(window.location.href)}
                        className="flex items-center justify-center w-8 h-8 text-white bg-gray-500 rounded-full hover:bg-gray-700"
                      >
                        <FaRegCopy />
                      </button>
                      {copiedMessage && (
                        <div className="absolute top-0 right-0 bg-green-500 text-white text-xs p-1 rounded">
                          Copied!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* ------------------------end of share buttons------------------------------------------ */}
              </div>
              {/* ----------------------------Latest Posts------------------------------------- */}
              <div className=" p-6  lg:w-1/4 border-l">
                <div className="">
                  <h3 className="text-orange-500 text-xl font-semibold mb-10">
                    Latest Posts
                  </h3>

                  <div className="flex flex-col gap-4">
                    {latestPosts.map((post) => (
                      <div
                        className=" flex justify-between items-start gap-4"
                        key={post.id}
                      >
                        <div className="flex lg:flex-row gap-4 items-start">
                          <Link to={`/blogs/${post.id}`}>
                            <img
                              src={post.imageUrl}
                              title={post.title}
                              alt={post.title}
                              // width={100}
                              className="object-cover w-20"
                            />
                          </Link>

                          <div>
                            <h5 className="text-sm no-underline">
                              <Link
                                className="no-underline text-black"
                                to={`/blogs/${post.id}`}
                              >
                                {post.title}
                              </Link>
                            </h5>

                            <div className="flex gap-1 text-start">
                              <span className="name text-xs text-orange-500 italic">
                                admin
                              </span>
                              <span>
                                <LuDot />
                              </span>
                              <span className="date text-xs">
                                {" "}
                                {formatDate(post.date)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="text-center">
                      <Link
                        to="/blogs"
                        className=" mt-8 bg-[#2AAA8A] text-white px-2 py-1 rounded hover:bg-[#478778] transition-colors"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                {/* End Latest Post */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSingle;
