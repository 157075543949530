import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegWindowClose } from "react-icons/fa";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { toast } from "react-toastify";
import logo from "../assets/logo.png";

const AdminNavbar = () => {
  const [isShow, setIsShow] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const location = useLocation();

  const [showSubMenu, setShowSubMenu] = useState(false);

  //   handle authentication
  const handleLogout = () => {
    auth.signOut();

    toast.success("you are logged out");

    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  //   handle user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // Set default route to /admin/add_Blog
  useEffect(() => {
    if (
      location.pathname === "/supercontrol" ||
      location.pathname === "/supercontrol/"
    ) {
      navigate("/supercontrol/add-Blog");
    }
  }, [location, navigate]);

  return (
    <header className="w-full fixed z-20 bg-white text-black border-b">
      <>
        <nav className="flex lg:px-20 px-10 py-2 w-full justify-between items-center">
          <div className="w-32">
            <img src={logo} alt="" />
          </div>
          <div className="lg:block hidden">
            <ul className="flex gap-10 font-outfit">
              <li
                className={`p-2 cursor-pointer transition-all ease-in-out duration-100 ${
                  location.pathname === "/supercontrol/add-blog"
                    ? "bg-orange-500 rounded-lg text-white"
                    : "hover:text-orange-400"
                }`}
              >
                <Link to="/supercontrol/add-blog">Add Blogs</Link>
              </li>
              <li
                className={`p-2 cursor-pointer transition-all ease-in-out duration-100 ${
                  location.pathname === "/supercontrol/appointments"
                    ? "bg-orange-500 rounded-lg text-white"
                    : "hover:text-orange-400"
                }`}
              >
                <Link to="/supercontrol/appointments">Appointments</Link>
              </li>
              <li
                className={`p-2 cursor-pointer transition-all ease-in-out duration-100 ${
                  location.pathname === "/supercontrol/client-details"
                    ? "bg-orange-500 rounded-lg text-white"
                    : "hover:text-orange-400"
                }`}
              >
                <Link to="/supercontrol/client-details">Client Details</Link>
              </li>
            </ul>
          </div>
          <div className="flex items-center gap-4">
            {user ? (
              <button
                className="text-center text-sm font-montserrat font-medium justify-center flex items-center gap-2 rounded-xl lg:py-4 lg:px-8 px-4 py-2 bg-[#FF4D30] text-white shadow-custom hover:shadow-custom-hovered transition-all duration-300 ease-in-out button"
                onMouseEnter={() => setShowSubMenu(true)}
                onMouseLeave={() => setShowSubMenu(false)}
              >
                <span className="hover:text-orange-400">admin</span>
                {showSubMenu && (
                  <ul
                    className="absolute z-21 transition-all ease-in-out duration-300 text-center p-2 shadow-lg flex bg-black flex-col gap-2 rounded-xl"
                    style={{ top: "2rem", width: "100%" }}
                  >
                    <li className="hover:text-orange-500">
                      <Link onClick={handleLogout}>Logout</Link>
                    </li>
                  </ul>
                )}
              </button>
            ) : (
              <button className="text-center text-lg font-montserrat font-medium justify-center flex items-center gap-2 rounded-xl lg:py-4 lg:px-8 px-4 py-2 bg-[#FF4D30] text-white shadow-custom hover:shadow-custom-hovered transition-all duration-300 ease-in-out button">
                <Link to="/supercontrol/signin">Login</Link>
              </button>
            )}
          </div>
        </nav>
      </>
      {isShow && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-10"
            onClick={() => setIsShow(false)}
          ></div>

          <nav className="absolute top-0 right-0 h-screen bg-white text-black font-montserrat font-medium w-1/2 flex justify-centter pt-6 items-center flex-col gap-10 transition-all ease-in-out duration-300 z-20">
            <button onClick={() => setIsShow(false)} className="text-xl">
              <FaRegWindowClose />
            </button>
            <ul className="flex flex-col justify-center gap-4 font-outfit">
              <li
                className={`cursor-pointer transition-all ease-in-out duration-100 ${
                  location.pathname === "/supercontrol/add-blog"
                    ? "bg-orange-500 rounded-lg text-white"
                    : "hover:text-orange-400"
                }`}
                onClick={() => setIsShow(false)}
              >
                <Link to="/supercontrol/add-blog">Add Blogs</Link>
              </li>
              <li
                className={`cursor-pointer transition-all ease-in-out duration-100 ${
                  location.pathname === "/supercontrol/client-details"
                    ? "bg-orange-500 rounded-lg text-white"
                    : "hover:text-orange-400"
                }`}
                onClick={() => setIsShow(false)}
              >
                <Link to="/supercontrol/client-details">Client Details</Link>
              </li>
              <li
                className={`cursor-pointer transition-all ease-in-out duration-100 ${
                  location.pathname === "/supercontrol/appointments"
                    ? "bg-orange-500 rounded-lg text-white"
                    : "hover:text-orange-400"
                }`}
                onClick={() => setIsShow(false)}
              >
                <Link to="/supercontrol/appointments">Appointments</Link>
              </li>
              <Link>
                <li
                  className="cursor-pointer hover:text-orange-400 transition-all ease-in-out duration-100 bottom-2"
                  onClick={() => {
                    handleLogout();
                    setIsShow(false);
                  }}
                >
                  Logout
                </li>
              </Link>
            </ul>
          </nav>
        </>
      )}
    </header>
  );
};

export default AdminNavbar;
