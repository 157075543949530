import React, { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../config/firease"; // Import your Firebase configuration

const isSunday = (dateString) => {
  const date = new Date(dateString);
  return date.getDay() === 0; // 0 corresponds to Sunday
};

const isValidTime = (timeString) => {
  const time = parseInt(timeString.split(":")[0], 10);
  return time >= 9 && time <= 17; // Check if the time is between 9 am and 5 pm
};

const Appointment = () => {
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    age: "",
    contactNumber: "",
    date: "",
    time: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if it's Sunday or outside the valid time range
    if (isSunday(formData.date) || !isValidTime(formData.time)) {
      alert("We are only operational Mon-Sat, 9am-5pm.");
      console.log("Invalid booking time or date!");
      return;
    } else {
      alert("We'll get back to you soon!!");
    }

    try {
      const appointmentsCollection = collection(db, "appointments");
      await addDoc(appointmentsCollection, {
        ...formData,
        timestamp: serverTimestamp(), // Add a timestamp for when the appointment was submitted
      });

      // Clear form after submission
      setFormData({
        name: "",
        gender: "",
        age: "",
        contactNumber: "",
        date: "",
        time: "",
      });
      console.log("Appointment submitted successfully!");
    } catch (error) {
      console.error("Error submitting appointment:", error);
    }
  };
  console.log(formData);

  return (
    <div className="flex flex-col justify-start items-center w-full py-28 h-full text-center px-10">
      <h2 className="text-heading text-3xl font-semibold pb-6">
        Book a consultation now
      </h2>
      <h3 className="pb-10 text-xl font-medium text-secondary">
        Our experts will be with you in the mean time!
      </h3>
      <form
        onSubmit={handleSubmit}
        className="lg:flex lg:flex-row flex flex-col flex-wrap gap-10 justify-center items-center lg:px-60 px-10 "
      >
        <label className="lg:w-80 w-72 flex flex-col  lg:gap-4 gap-2 text-heading text-sm font-semibold">
          <span>Name</span>
          <input
            type="text"
            name="name"
            required
            value={formData.name}
            onChange={handleChange}
            className="border-b border-black outline-none rounded-lg text-sm font-normal pl-2 bg-transparent"
          />
        </label>
        <label className="lg:w-80 w-72 flex flex-col lg:gap-4 gap-2 text-heading text-sm font-semibold">
          <span>Age</span>
          <input
            type="text"
            name="age"
            required
            value={formData.age}
            onChange={handleChange}
            className="border-b border-black outline-none rounded-lg text-sm font-normal pl-2 bg-transparent"
          />
        </label>
        <label className="lg:w-80 w-72 flex flex-col  lg:gap-4 gap-2 text-heading text-sm font-semibold">
          Gender:
          <select
            name="gender"
            required
            value={formData.gender}
            onChange={handleChange}
            className="border-b border-black outline-none rounded-lg text-sm font-normal pl-2 bg-transparent"
          >
            <option value="" disabled className="">
              Select Gender
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </label>
        <label className="lg:w-80 w-72 flex flex-col  lg:gap-4 gap-2 text-heading text-sm font-semibold">
          <span>Contact Number</span>
          <input
            type="text"
            name="contactNumber"
            required
            value={formData.contactNumber}
            onChange={handleChange}
            className="border-b border-black outline-none rounded-lg text-sm font-normal pl-2 bg-transparent"
          />
        </label>
        <label className="lg:w-80 w-72 flex flex-col  lg:gap-4 gap-2 text-heading text-sm font-semibold">
          <span>Preferred Date & Time</span>
          <div className="flex">
            <input
              type="date"
              name="date"
              required
              value={formData.date}
              onChange={handleChange}
              className="border-b border-black outline-none rounded-lg text-sm font-normal px-2 lg:w-40 w-36 bg-transparent"
            />
            <input
              type="time"
              name="time"
              required
              value={formData.time}
              onChange={handleChange}
              className="border-b border-black outline-none rounded-lg text-sm font-normal px-2 lg:w-40 w-36  bg-transparent"
            />
          </div>
        </label>

        <button
          className="lg:w-80 w-72 bg-[#21685c] text-white text-sm font-medium py-3 rounded-3xl hover:-translate-y-1.5 transition-all ease-in-out duration-200 mt-auto"
          type="submit"
        >
          Book Now
        </button>
      </form>
    </div>
  );
};

export default Appointment;
