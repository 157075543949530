import anup from "../../assets/anup1.png"
import arpita from "../../assets/arpita.png"


export const ourTeam = [
  {
    img: arpita,
    name: "Arpita Biswas",
    role: "Founder & CEO",
    linkedIn: "https://www.linkedin.com/in/arpita-biswas-2521b7180/",
  },

  {
    img: anup,
    name: "Anup K Jana",
    role: "Co-Founder",
    linkedIn: "https://linkedin.com/in/anupkumarjana",
  },
];
