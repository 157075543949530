import React from "react";
import ShimmerUi from "./ShimmerUi";

export default function ShimmerUiPage() {
  return (
    <div className="lg:px-40 px-10 pt-32 flex flex-wrap gap-4">
      <ShimmerUi />
      <ShimmerUi />
      <ShimmerUi />
      <ShimmerUi />
      <ShimmerUi />
      <ShimmerUi />
      <ShimmerUi />
      <ShimmerUi />
      <ShimmerUi />
      <ShimmerUi />
      <ShimmerUi />
      <ShimmerUi />
      <ShimmerUi />
   
    </div>
  );
}
