import React from "react";
import { Outlet } from "react-router-dom";
// import Footer from "../components/footer/Footer";
import AdminNavbar from "./AdminNavbar";

const AdminLayout = () => {
  return (
    <div>
      <header className="z-[100] border-b-2 shadow-lg">
        <AdminNavbar/>
      </header>
      <main>
        <Outlet />
      </main>
     {/* <Footer/> */}
    </div>
  );
};

export default AdminLayout;
