import React from "react";
import Login from "../components/log-in/Login";

export default function LogInPage() {
  return (
    <>
     
      <Login />
    </>
  );
}
