import React, { useEffect, useState } from "react";
import { db, storage } from "../../config/firease";
import { getDoc, doc } from "firebase/firestore";
import { auth } from "../../config/firease";
import { ref, getDownloadURL } from "firebase/storage";
import NotProUser from "./NotProUser";
import { FaWhatsapp } from "react-icons/fa";
// import ShimmerUI from "../ShimmerUI/ShimmerUiPage"

const DashProfile = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async (user) => {
      try {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserDetails(userData);
        } else {
          setUserDetails(null);
        }
      } catch (err) {
        console.error("Error fetching user details:", err);
        setUserDetails(null);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserDetails(user);
      } else {
        setUserDetails(null);
        setPdfUrl(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchPdfUrl = async () => {
      if (userDetails && userDetails.name && userDetails.uid) {
        const pdfPath = `diet-charts/${userDetails.name
          .split(" ")
          .join("+")}${userDetails.uid.substring(0, 4)}.pdf`;

        const pdfFileRef = ref(storage, pdfPath);

        try {
          const url = await getDownloadURL(pdfFileRef);
          setPdfUrl(url);
        } catch (error) {
          console.error("Error getting PDF download URL:", error);
          setPdfUrl(null); // Reset pdfUrl if there is an error
        }
      } else {
        setPdfUrl(null);
      }
    };

    fetchPdfUrl();
  }, [userDetails]);

  return (
    <div className="flex flex-col lg:px-40 px-10 py-40 bg-transparent w-full lg:h-screen h-full">
      <div className="flex flex-col gap-1 mb-10">
        <span className="text-sm text-heading">Hello!</span>
        <span className="text-normal font-semibold text-heading">
          {auth?.currentUser?.displayName}
        </span>
      </div>
      <section className="flex lg:flex-row flex-col lg:gap-0 gap-10 lg:justify-between items-start">
        {userDetails ? (
          <>
            {userDetails.proUser !== undefined ? (
              userDetails.proUser ? (
                <div className="flex flex-col gap-4 justify-center lg:justify-start items-center font-montserrat">
                  <div className="flex flex-wrap gap-4 justify-center lg:justify-start items-center">
                    <div className="w-28 h-28 bg-[#DBA979] flex justify-center items-center flex-col rounded-lg ">
                      <div className="text-normal font-thin text-[#333333]">
                        Age
                      </div>
                      <div className="text-lg font-semibold">
                        {userDetails.age || "Not found"}
                      </div>
                    </div>
                    <div className="w-28 h-28 bg-[#ECCA9C] flex justify-center items-center flex-col rounded-lg">
                      <div className="text-normal font-thin text-[#333333]">
                        Weight
                      </div>
                      <div className="text-lg font-semibold">
                        {userDetails.weight || "Not found"}
                        <span className="text-sm"> Kg</span>
                      </div>
                    </div>
                    <div className="w-28 h-28 bg-[#E8EFCF] flex justify-center items-center flex-col rounded-lg">
                      <div className="text-normal font-thin text-[#333333]">
                        Height
                      </div>
                      <div className="text-lg font-semibold">
                        {userDetails.height || "Not found"}{" "}
                        <span className="text-sm">ft</span>
                      </div>
                    </div>
                    <div className="w-28 h-28 bg-[#AFD198] flex justify-center items-center flex-col rounded-lg ">
                      <div className="text-normal font-thin text-[#333333]">
                        BMI
                      </div>
                      <div className="text-lg font-semibold">
                        {userDetails.bmi || "Not found"}{" "}
                        <span className="text-sm">
                          {" "}
                          Kg/m<sup>2</sup>
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* ------------large boxes---------------- */}
                  <div className="flex flex-wrap gap-4 justify-center lg:justify-start items-center">
                    <div className="w-40 h-44 bg-[#B3A398] flex justify-center items-center flex-col rounded-lg">
                      <div className="text-normal font-thin text-[#333333]">
                        Goal
                      </div>
                      <div className="text-sm font-normal">
                        {userDetails.goal || "Not found"}
                      </div>
                    </div>
                    <div className="w-40 h-44  bg-[#DBB5B5] flex justify-center items-center flex-col rounded-lg ">
                      <div className="text-normal font-thin text-[#333333]">
                        Disease
                      </div>
                      <div className="text-sm font-normal">
                        {userDetails.disease || "Not found"}
                      </div>
                    </div>
                  </div>
                  {/* --------------------------- */}
                  <div className="flex justify-center items-center flex-col">
                    <div className="text-lg font-semibold">
                      Thank you for being a pro user!
                    </div>
                  </div>
                </div>
              ) : (
                <NotProUser />
              )
            ) : (
              <NotProUser />
            )}
          </>
        ) : (
          <NotProUser />
        )}
        <div>
          {pdfUrl ? (
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
              <button
                className="lg:text-sm bg-[#FEB941]  px-4 py-2 rounded-lg w-28 h-28"
                style={{ fontWeight: "500" }}
              >
                Diet Chart
              </button>
            </a>
          ) : (
            <button
              className="lg:text-sm bg-[#21695c] text-white px-4 py-2 rounded-lg"
              style={{ fontWeight: "500" }}
            >
              <p>You've no diet-chart!</p>
            </button>
          )}
          <div className="flex flex-col gap-2 mt-10">
            <p>For any assistance</p>
            <a
              href="https://wa.me/916296709257"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <button
                className="text-sm bg-[#21695c] text-white px-4 py-2 flex justify-center items-center rounded-lg gap-2"
                style={{ fontWeight: "500" }}
              >
                <span>
                  <FaWhatsapp />
                </span>
                <span>WhatsApp Us</span>
              </button>
            </a>
          </div>
        </div>
      </section>
      {/* <div className="flex gap-10 items-center pt-10">
        <button>
          {isAdmin && (
            <Link
              to="/admin/appointments"
              className="bg-[#21695c] text-white px-4 py-2 rounded-lg"
            >
              See Appointments
            </Link>
          )}
        </button>

        <button>
          {isAdmin && (
            <Link
              to="/admin/client-details"
              className="bg-[#21695c] text-white px-4 py-2 rounded-lg"
            >
              Update Client Data
            </Link>
          )}
        </button>
        <button>
          {isAdmin && (
            <Link
              to="/admin/add-blog"
              className="bg-[#21695c] text-white px-4 py-2 rounded-lg"
            >
              Add Blog
            </Link>
          )}
        </button>
      </div> */}
    </div>
  );
};

export default DashProfile;
