import React, { useState, useEffect } from "react";
import { googleProvider, auth, db } from "../../config/firease";
import {
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import Dashboard from "../Dashboard/Dashboard";
import { useNavigate } from "react-router-dom";
import google from "../../assets/google.png";
import logo from "../../assets/4.png";
import { doc, setDoc } from "firebase/firestore"; // Import collection and setDoc functions
import { admin4, admin5 } from "../../constants";

function Login() {
  const [signedIn, setSignedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Ensure Firebase Auth persistence is set to local
    setPersistence(auth, browserLocalPersistence).catch((error) => {
      console.error("Failed to set persistence:", error);
    });
  }, []);

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      const user = auth.currentUser;

      setSignedIn(true);

      // Add user to Firestore if they are new
      if (user.metadata.creationTime === user.metadata.lastSignInTime) {
        await setDoc(doc(db, "users", user.uid), {
          uid: user.uid,
          name: user.displayName,
          photoURL: user.photoURL,
        });
      }

      const userName = user.displayName.toLowerCase().replace(/\s/g, "");

      // Navigate to dashboard if the user is not an admin
      if (user.email !== admin4 && user.email !== admin5) {
        navigate(`/dashboard/${userName}`);
      }

      console.log("Signed-in");
    } catch (err) {
      console.error("Error signing in:", err);
    }
  };

  return (
    <div className="flex justify-center items-center overflow-hidden bg-home paddingtext-center w-full h-screen lg:px-40 px-10">
      {signedIn ? (
        <Dashboard />
      ) : (
        <div className="flex flex-col justify-center items-center gap-4 bg-[#21695c] rounded-lg shadow-lg p-10 ">
          <img src={logo} alt="user" className="w-40 pb-4" />
          <p className="text-white">Sign In To Your Account</p>
          <button
            onClick={signInWithGoogle}
            className="flex items-center gap-2 bg-white px-4 py-2 rounded shadow-lg"
          >
            <img src={google} alt="google-logo" className="w-6" />
            <span> Sign in with Google</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default Login;
