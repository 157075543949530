import React, { useEffect, useState } from "react";
import Home from "../components/home/Home";
import BmiCalculator from "../components/BMICalculator/BmiCalculator";
import StickyBar from "../components/StickyBar/StickyBar";
// import Plans from "../components/plans/Plans";
import Contact from "../components/contact/Contact";
import Feature from "../components/feature/Feature";

export default function HomePage() {
  // const [showStickyBar, setShowStickyBar] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setShowStickyBar(window.scrollY > 150);
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []); // Empty dependency array to run the effect only once

  return (
    <>
      <div className="bg-home page-padding text-lg-start text-center text-wrap">
        <Home />
        <Feature />
        {/* {showStickyBar && <StickyBar />} */}
        <BmiCalculator />
        {/* <Plans /> */}
        <Contact />
      </div>
    </>
  );
}
