import React from "react";

export default function FooterBottom() {
  return (
    <div className="text-center p-3 bg-[#1c594e]">
      <span className="text-[#272626]"> © {new Date().getFullYear()} Copyright </span>
      <a className="text-white text-decoration-none" href="/">
        DietGo
      </a>
    </div>
  );
}
