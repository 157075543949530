import React from "react";
import Appointment from "../components/appointment/Appointment";


export default function AppointmentPage() {
  return (
    <div className="">
     
      <Appointment />
    </div>
  );
}
