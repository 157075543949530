import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
// import { ElfsightWidget } from "react-elfsight-widget";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_t57rln6",
        "template_pq73fs7",
        form.current,
        "nubMY8K2OKbYwO_Oe"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact flex items-center font-poppins py-16">
      {/* <ElfsightWidget widgetId="ca4311ce-4611-4a46-8619-32e4ef07607b" lazy /> */}
      <div className="contact container col-xxl-8" id="contact">
        <div className="lg:flex lg:flex-row flex flex-col justify-between gap-10">
          <div className="flex flex-col  gap-2" data-aos="fade-right">
            <h2 className="text-2xl font-bold pb-4 text-[#2AAA8A]">
              CONNECT WITH US
            </h2>
            <div className="flex gap-4 items-center lg:justify-start justify-center">
              <i className="fa-solid fa-envelope text-[#21695c]"></i>
              <a
                className="gmail text-gray-700 hover:underline"
                href="mailto:queries.dietgo@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                contact@dietgo.in
              </a>
            </div>
            <div className="flex gap-4 items-center lg:justify-start justify-center">
              <i className="fa-solid fa-square-phone text-[#21695c]"></i>
              <span>+91-6296709257</span>
            </div>
            <div className="flex gap-4 text-3xl text-[#21695c] mt-8 lg:justify-start justify-center">
              <a
                href="https://www.facebook.com/profile.php?id=61553149104810"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/dietgo.insta"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://twitter.com/_DietGo_"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UC6eX6LoALg8foXOuihgIzjw"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-4" data-aos="fade-left">
            <form
              ref={form}
              id="myForm"
              action="https://formsubmit.co/queries.dietgo@gmail.com"
              target="_blank"
              name="form"
              onSubmit={sendEmail}
              className="w-full flex flex-col gap-4"
            >
              <input
                type="text"
                name="user_name"
                placeholder="Your Name"
                required
                className="input-field mb-4 px-4 py-2 rounded-lg outline-none border"
              />
              <input
                type="email"
                name="user_email"
                placeholder="Email"
                required
                className="input-field mb-4 px-4 py-2 rounded-lg outline-none border"
              />
              <input
                type="text"
                className="input-field mb-4 px-4 py-2 rounded-lg outline-none border"
                placeholder="Contact Number"
                name="phone"
                required
              />
              <textarea
                name="message"
                rows="2 "
                placeholder="Your Message"
                className=" px-4 py-2 rounded-lg outline-none border"
              ></textarea>
              <button
                id="submit"
                type="submit"
                value="Send"
                className="btn btn2 bg-[#21695c] text-white py-2 px-4 rounded-lg  "
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
