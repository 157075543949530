import React from "react";
import FooterBottom from "./FooterBottom";
// import "./Footer.css";
import footerLogo from "../../assets/2-full.png"
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer ">
      <footer className="text-center text-normal-start bg-[#21695c] w-full ">
        <section className=" text-center  py-3 px-10 lg:px-40  w-full text-white">
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 lg:justify-between lg:items-start justify-center items-center py-4 text-lg-start text-center">
            <div className="flex flex-col lg:justify-start lg:items-start justify-center items-center text-wrap lg:text-left">
              {/* <h6 className="text-uppercase fw-bold mb-4 text-normal font-semibold">
                DietGo
              </h6> */}
              <img src={footerLogo} alt="logo"  className="lg:w-40 w-32 pb-4"/>

              <p className="max-w-80 text-normal font-thin">
                <strong>DietGo</strong> offers affordable, personalized diet plans tailored for
                various health conditions and effective weight
                management.
              </p>
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-normal font-semibold">
                Tools
              </h6>

              <p className="text-white text-decoration-none text-normal font-thin">
                BMI Calculator
              </p>
              <p className="text-white text-decoration-none text-normal font-thin">
                Weight Management
              </p>
              <p className="text-white text-decoration-none text-normal font-thin">
                Skin Toning
              </p>
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-normal font-semibold">
                Useful links
              </h6>
              <p>
                <Link
                  to="/blogs"
                  className="text-white text-decoration-none text-normal font-thin"
                >
                  DietGo Blogs
                </Link>
              </p>
              <p>
                <Link
                  to="/offerings"
                  className="text-white text-decoration-none text-normal font-thin"
                >
                  Offerings
                </Link>
              </p>
              <p>
                <Link
                  to="/plans"
                  className="text-white text-decoration-none text-normal font-thin"
                >
                  Plans
                </Link>
              </p>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-normal font-semibold">
                Contact
              </h6>

              <p className="text-normal font-thin">
                <i className="fas fa-home mr-3"></i> Kolkata, India
              </p>
              <p className="text-normal font-thin">
                <i className="fas fa-envelope mr-3"></i> contact@dietgo.in
              </p>
              <p className="text-normal font-thin">
                <i className="fas fa-phone mr-3"></i> +91-6296709257
              </p>
            </div>
          </div>
        </section>
        <div className="pb-16">
          <FooterBottom />
        </div>
      </footer>
    </div>
  );
}

export default Footer;
