import React, { useState, useEffect } from "react";
import logo from "../../assets/2-full.png";
import { IoMdClose } from "react-icons/io";
import { CiMenuBurger } from "react-icons/ci";
import { Link, useLocation } from "react-router-dom";
import {
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  getAuth,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { IoIosArrowDropright } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [activeNavItem, setActiveNavItem] = useState("");
  const navigate = useNavigate();
  const [isDPClicked, setIsDPClicked] = useState(false);
  const auth = getAuth();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setUser(user);
        });
        return () => unsubscribe();
      })
      .catch((error) => {
        console.error("Failed to set persistence:", error);
      });
  }, [auth]);

  const handleSignOut = async () => {
    try {
      navigate("/");
      await signOut(auth);
    } catch (err) {
      console.log(err);
    }
  };

  const dashboardRoute = user
    ? `/dashboard/${user.displayName?.toLowerCase().replace(/\s/g, "")}`
    : "/dashboard";

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavItemClick = (item) => {
    setActiveNavItem(item);
    setIsMenuOpen(false);
  };

  const handleOptionsClicked = () => {
    setIsDPClicked(!isDPClicked);
  };

  return (
    <nav className="bg-white shadow-sm fixed z-30 w-full">
      <div className="flex justify-between items-center py-lg-3 py-3 page-padding">
        <Link to="/">
          <img src={logo} className="cursor-pointer lg:w-36 w-28" alt="logo" />
        </Link>

        <div className="lg:block hidden">
          <ul className="lg:flex gap-10 justify-center items-center list-none font-poppins font-medium">
            <li
              className={`list-none ${
                activeNavItem === "home" ? "text-[#2AAA8A]" : "text-gray-700"
              } hover:text-[#2AAA8A]`}
            >
              <Link
                className="flex items-center"
                to="/"
                onClick={() => handleNavItemClick("home")}
              >
                Home
              </Link>
            </li>
            <li
              className={`list-none ${
                activeNavItem === "about" ? "text-[#2AAA8A]" : "text-gray-700"
              } hover:text-[#2AAA8A]`}
            >
              <Link
                className="flex items-center"
                to="/about"
                onClick={() => handleNavItemClick("about")}
              >
                About Us
              </Link>
            </li>
            <li
              className={`list-none ${
                activeNavItem === "blogs" ? "text-[#2AAA8A]" : "text-gray-700"
              } hover:text-[#2AAA8A]`}
            >
              <Link
                className="flex items-center"
                to="/blogs"
                onClick={() => handleNavItemClick("blogs")}
              >
                Blogs
              </Link>
            </li>

            <li
              className={`list-none ${
                activeNavItem === "plans" ? "text-[#2AAA8A]" : "text-gray-700"
              } hover:text-[#2AAA8A]`}
            >
              <Link
                className="flex items-center"
                to="/plans"
                onClick={() => handleNavItemClick("plans")}
              >
                Plans
              </Link>
            </li>
            <li
              className={`list-none ${
                activeNavItem === "contact" ? "text-[#2AAA8A]" : "text-gray-700"
              } hover:text-[#2AAA8A]`}
            >
              <Link
                className="flex items-center"
                to="/contact"
                onClick={() => handleNavItemClick("contact")}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex items-center gap-2 relative">
          {user ? (
            <>
              <div
                className="flex gap-1 items-center cursor-pointer"
                onClick={() => setIsDPClicked(!isDPClicked)}
              >
                {user.photoURL === undefined ? (
                  <span className="lg:w-10 lg:h-10 w-8 h-8 runded-full cursor-pointer">
                    <CgProfile />
                  </span>
                ) : (
                  <img
                    src={user.photoURL}
                    alt="User"
                    className="lg:w-10 lg:h-10 w-8 h-8 rounded-full cursor-pointer"
                  />
                )}
                <span>
                  <IoIosArrowDown />
                </span>
              </div>

              {isDPClicked && (
                <div className="absolute top-10 z-50 flex flex-col items-center text-center justify-center w-20 bg-[#AFD198] text-sm rounded-lg shadow-sm">
                  <Link
                    to={dashboardRoute}
                    className="p-2 hover:bg-white w-full border-b"
                    onClick={handleOptionsClicked}
                  >
                    <button className=" ">Profile</button>
                  </Link>
                  <hr />
                  <button
                    onClick={handleSignOut}
                    className=" p-2 hover:bg-white w-full"
                  >
                    Sign out
                  </button>
                </div>
              )}
            </>
          ) : (
            <Link to="/login" className="text-gray-700 hover:text-[#2AAA8A]">
              <button className="border-[#21695c] lg:border lg:px-4 lg:py-2 px-2 py-1 rounded-lg text-sm bg-[#21695c] font-medium text-white hover:scale-95 transition-all duration-300 ease-in-out">
                Sign in
              </button>
            </Link>
          )}
          <button onClick={handleMenuToggle} className="lg:hidden block">
            {isMenuOpen ? (
              <IoMdClose className="text-2xl" />
            ) : (
              <CiMenuBurger className="text-2xl" />
            )}
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className={`h-screen ${isMenuOpen ? "fadeanime" : ""}`}>
          <ul className="flex flex-col justify-center items-start pt-32 pl-[40%] gap-6 text-normal font-thin ">
            <li>
              <Link
                className="flex items-center"
                to="/"
                onClick={handleNavItemClick}
              >
                HOME
              </Link>
            </li>
            <li>
              <Link
                className="text-gray-700 hover:text-[#2AAA8A]"
                to="/about"
                onClick={handleNavItemClick}
              >
                ABOUT US
              </Link>
            </li>
            <li>
              <Link
                className="flex items-center"
                to="/blogs"
                onClick={handleNavItemClick}
              >
                BLOGS
              </Link>
            </li>

            <li>
              <Link
                className="text-gray-700 hover:text-[#2AAA8A]"
                to="/plans"
                onClick={handleNavItemClick}
              >
                PLANS
              </Link>
            </li>
            <li>
              <Link
                className="text-gray-700 hover:text-[#2AAA8A]"
                to="/contact"
                onClick={handleNavItemClick}
              >
                CONTACT
              </Link>
            </li>
          </ul>
          <div
            className="btn-div pt-4 flex lg:justify-start justify-center flex-wrap gap-4"
            data-aos="fade-up"
          >
            <a
              href="https://wa.me/916296709257"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <button
                className="text-sm bg-[#21695c] text-white px-4 py-2 flex justify-center items-center rounded-lg gap-2"
                style={{ fontWeight: "500" }}
              >
                <span>
                  <FaWhatsapp />
                </span>{" "}
                <span>WhatsApp Us</span>
              </button>
            </a>
            <Link to="/appointment">
              <button
                className="text-sm bg-transparent px-4 py-2 flex justify-center items-center rounded-lg gap-2"
                style={{ border: "1px solid #21635c", fontWeight: "500" }}
              >
                <span>Get Appointment</span>
                <span>
                  <IoIosArrowDropright />
                </span>
              </button>
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
