import React from "react";
import { aboutCardData } from "./AboutCardData";

const WhatWeOffer = () => {
  return (
    <div className="py-20 flex flex-col gap-6 min-h-screen text-center">
      <div className="flex flex-col gap-4 pb-4 " data-aos="fade-up">
        <h3 className="text-2xl font-bold text-[#2AAA8A] font-montserrat">
          WHAT WE OFFER
        </h3>
        <h4 className="text-secondary text-balance pb-10">
          We provide comprehensive solutions tailored to your needs, ensuring
          top-quality results and exceptional service. Trust us to deliver
          innovative and reliable support for your business.
        </h4>
      </div>
      {/* <hr /> */}
      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2" data-aos="fade-up">
        {aboutCardData.map((data, index) => (
          <div
            key={index}
            className="flex flex-col gap-2 justify-center items-center text-center px-4 py-10 bg-white rounded-lg border border-orange-400 shadow-lg "
          >
            <span
              className="text-4xl font-bold text-[#1A4D2E] flex justify-center"
              style={{ color: `${data.bg}` }}
            >
              {data.icons}
            </span>
            <span className="font-montserrat text-xl font-semibold">
              {data.title}
            </span>
            <span className="font-outfit text-sm font-light leading-relaxed text-balance">
              {data.content}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatWeOffer;
