import React, { useState, useEffect } from "react";
import { db, storage } from "../config/firease";
import {
  collection,
  doc,
  setDoc,
  // deleteDoc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import AdminNavbar from "../Layout/AdminNavbar";


const AdminClientDetails = () => {
  const [formData, setFormData] = useState({
    uid: "",
    name: "",
    photoURL: "",
    age: "",
    height: "",
    weight: "",
    bmi: "",
    goal: "",
    disease: "",
    proUser: false,
    pdfFile: null,
    pdfUrl: "",
  });

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [pdfExist, setPdfExist] = useState(false);

  //pagination
  // const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 5;

  //  const totalPages = Math.ceil(users.length / appointmentsPerPage);

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(users.length / usersPerPage);

   const nextPage = () => {
     if (currentPage < totalPages) {
       setCurrentPage(currentPage + 1);
     }
   };

   const prevPage = () => {
     if (currentPage > 1) {
       setCurrentPage(currentPage - 1);
     }
   };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);
        const usersData = usersSnapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
        //  setAppointments(users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    getUsers();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      pdfFile: file,
      pdfUrl: "",
    });
  };

  const handleAddOrUpdate = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const userCollection = collection(db, "users");

      // Existing user document or a new document with uid
      const userDoc = formData.uid
        ? doc(userCollection, formData.uid)
        : doc(userCollection);

      // Handle PDF existence check and upload
      const pdfRef = ref(
        storage,
        `diet-charts/${formData?.name
          .split(" ")
          .join("+")}${formData?.uid.substring(0, 4)}.pdf`
      );

      // Existing PDF check
      const pdfExists = await getDownloadURL(pdfRef).then(
        () => true,
        (error) => {
          if (error.code === "storage/object-not-found") {
            return false;
          }
          throw error;
        }
      );

      if (pdfExists) {
        setPdfExist(true);
        setLoading(false);
        return;
      }

      await uploadBytes(pdfRef, formData.pdfFile);
      const newPdfUrl = await getDownloadURL(pdfRef);

      const userDocData = {
        name: formData.name,
        age: formData.age,
        photoURL: formData.photoURL,
        height: formData.height,
        weight: formData.weight,
        bmi: formData.bmi,
        goal: formData.goal,
        disease: formData.disease,
        proUser: formData.proUser,
        pdfUrl: newPdfUrl,
      };

      // Validate and update user document
      for (const key in userDocData) {
        if (userDocData.hasOwnProperty(key) && userDocData[key] === undefined) {
          console.error(`Field '${key}' is undefined.`);
          setLoading(false);
          return;
        }
      }

      await setDoc(userDoc, {
        uid: formData.uid,
        ...userDocData,
      });

      // Update local state
      if (selectedUserId) {
        setUsers(
          users.map((user) =>
            user.uid === selectedUserId
              ? { uid: selectedUserId, ...formData, pdfUrl: newPdfUrl }
              : user
          )
        );
      } else {
        setUsers([
          ...users,
          { uid: formData.uid, ...formData, pdfUrl: newPdfUrl },
        ]);
      }

      setFormData((prevData) => ({
        ...prevData,
        pdfUrl: newPdfUrl,
        pdfFile: null,
      }));
      setSelectedUserId(null);
      setPdfExist(false);
      setLoading(false);
    } catch (error) {
      console.error("Error adding/updating user:", error);
      setLoading(false);
    }
  };

  // const handleDelete = async (userId) => {
  //   try {
  //     const userRef = doc(collection(db, "users"), userId);
  //     await deleteDoc(userRef);
  //     setUsers(users.filter((user) => user.id !== userId));
  //   } catch (error) {
  //     console.error("Error deleting user:", error);
  //   }
  // };

  const handleUserClick = (userId) => {
    const selectedUser = users.find((user) => user.uid === userId);

    // Create a new object with default values for all form fields
    const defaultFormData = {
      uid: "",
      name: "",
      photoURL: "",
      age: "",
      height: "",
      weight: "",
      bmi: "",
      goal: "",
      disease: "",
      proUser: false,
      pdfFile: null,
      pdfUrl: "",
    };

    // Merge the default form data with the selected user's data
    const updatedFormData = { ...defaultFormData, ...selectedUser };

    // Update the form data
    setFormData({
      ...updatedFormData,
    });

    setSelectedUserId(userId);
    setPdfExist(false);
  };

  const handleCheckboxChange = (e) => {
    const newProUserValue = e.target.checked;

    setFormData((prevData) => ({
      ...prevData,
      proUser: newProUserValue,
    }));

    const userCollection = collection(db, "users");
    const userDoc = doc(userCollection, formData.uid);

    updateDoc(userDoc, {
      proUser: newProUserValue,
    })
      .then(() => {
        console.log("Firestore document updated successfully");
      })
      .catch((error) => {
        console.error("Error updating Firestore document:", error);
      });
  };

  const handleDeletePdf = async () => {
    try {
      const pdfRef = ref(
        storage,
        `diet-charts/${formData.name
          .split(" ")
          .join("+")}${formData.uid.substring(0, 4)}.pdf`
      );
      console.log(pdfRef);
      await deleteObject(pdfRef);
      const userDoc = doc(collection(db, "users"), formData.uid);
      await updateDoc(userDoc, { pdfUrl: null });
      alert("PDF deleted. You can upload a new PDF.");
    } catch (error) {
      console.error("Error deleting PDF:", error);
    }
  };

  const handleCheckPdfExists = async () => {
    try {
      const pdfRef = ref(
        storage,
        `diet-charts/${formData.name
          .split(" ")
          .join("+")}${formData.uid.substring(0, 4)}.pdf`
      );

      // Try to get the download URL for the PDF file
      await getDownloadURL(pdfRef);

      // If the above line doesn't throw an error, it means the file exists
      setPdfExist(true);
      alert("PDF with the same URL already exists.");
    } catch (error) {
      // If an error occurs, it means the file doesn't exist
      setPdfExist(false);
      alert("PDF does not exist.");
    }
  };

  console.log(formData);

  return (
    <>
      <AdminNavbar />
      <div className="flex flex-col gap-10 flex-wrap justify-start items-center pt-20 px-20 bg-home">
        <h2 className="text-3xl text-heading font-semibold">
          Welcome Admin Arpita
        </h2>
        <div className="flex items-start flex-row-reverse justify-between gap-20">
          <form
            onSubmit={handleAddOrUpdate}
            className="flex flex-wrap gap-4 justify-center items-center"
          >
            <p className="bg-red-700 text-white p-2 rounded-lg font-bold">
              Never ever touch id, name, photoURL
            </p>
            <label className="flex gap-4 text-sm color-[#333333] bg-zinc-300 px-4 py-2 rounded-lg w-96">
              <span>ID:</span>
              <input
                className="bg-zinc-300 outline-none font-medium"
                type="text"
                name="id"
                value={formData.uid}
                onChange={(e) =>
                  setFormData({ ...formData, uid: e.target.value })
                }
              />
            </label>
            <label className="flex gap-4 text-sm color-[#333333] bg-zinc-300 px-4 py-2 rounded-lg w-96">
              <span>Name:</span>
              <input
                className="bg-zinc-300 outline-none font-medium"
                type="text"
                name="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </label>
            <label className="flex gap-4 text-sm color-[#333333] bg-zinc-300 px-4 py-2 rounded-lg w-96">
              <span>PhotoURL:</span>
              <input
                className="bg-zinc-300 outline-none font-medium"
                type="text"
                name="photoURL"
                value={formData.photoURL}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </label>
            <label className="flex gap-4 text-sm color-[#333333] bg-zinc-300 px-4 py-2 rounded-lg w-96 mt-10">
              <span>Age:</span>
              <input
                className="bg-zinc-300 outline-none font-medium"
                type="number"
                name="age"
                value={formData.age}
                onChange={(e) =>
                  setFormData({ ...formData, age: e.target.value })
                }
              />
            </label>
            <label className="flex gap-4 text-sm color-[#333333] bg-zinc-300 px-4 py-2 rounded-lg w-96 mt-10">
              <span>Height:</span>
              <input
                className="bg-zinc-300 outline-none font-medium"
                type="number"
                name="height"
                value={formData.height}
                onChange={(e) =>
                  setFormData({ ...formData, height: e.target.value })
                }
              />
            </label>
            <label className="flex gap-4 text-sm color-[#333333] bg-zinc-300 px-4 py-2 rounded-lg w-96">
              <span>Weight:</span>
              <input
                className="bg-zinc-300 outline-none font-medium"
                type="number"
                name="weight"
                value={formData.weight}
                onChange={(e) =>
                  setFormData({ ...formData, weight: e.target.value })
                }
              />
            </label>
            <label className="flex gap-4 text-sm color-[#333333] bg-zinc-300 px-4 py-2 rounded-lg w-96">
              <span>BMI:</span>
              <input
                className="bg-zinc-300 outline-none font-medium"
                type="number"
                name="bmi"
                value={formData.bmi}
                onChange={(e) =>
                  setFormData({ ...formData, bmi: e.target.value })
                }
              />
            </label>
            <label className="flex gap-4 text-sm color-[#333333] bg-zinc-300 px-4 py-2 rounded-lg w-96">
              <span>Goal:</span>
              <input
                className="bg-zinc-300 outline-none font-medium"
                type="text"
                name="goal"
                value={formData.goal}
                onChange={(e) =>
                  setFormData({ ...formData, goal: e.target.value })
                }
              />
            </label>
            <label className="flex gap-4 text-sm color-[#333333] bg-zinc-300 px-4 py-2 rounded-lg w-96">
              <span>Disease:</span>
              <input
                className="bg-zinc-300 outline-none font-medium"
                type="text"
                name="disease"
                value={formData.disease}
                onChange={(e) =>
                  setFormData({ ...formData, disease: e.target.value })
                }
              />
            </label>
            <label className="flex gap-4 text-sm color-[#333333] bg-zinc-300 px-4 py-2 rounded-lg w-96">
              <span>Pro-user:</span>
              <input
                className="bg-zinc-300 outline-none font-medium"
                type="checkbox"
                name="proUser"
                checked={formData.proUser}
                onChange={handleCheckboxChange}
              />
              <span>{formData.proUser ? "yes" : "no"}</span>
            </label>
            <button
              className={`border-4 px-3 py-1 rounded-lg bg-red-700 text-white ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              type="button"
              onClick={handleCheckPdfExists}
            >
              Check Diet Existence
            </button>{" "}
            <div>
              {pdfExist && (
                <>
                  <span>PDF Exists. </span>
                  <button
                    className="border-4 px-3 py-1 rounded-lg bg-red-700 text-white "
                    type="button"
                    onClick={handleDeletePdf}
                  >
                    Delete PDF
                  </button>
                </>
              )}
            </div>
            <label className="flex gap-4 text-sm color-[#333333] bg-zinc-300 px-4 py-2 rounded-lg">
              <span>Diet Chart:</span>
              <div className="flex gap-4 items-center"></div>
              <input
                className="bg-zinc-300 outline-none font-medium"
                type="file"
                name="pdfFile"
                onChange={handleFileChange}
              />
            </label>
            <button
              className={`bg-[#21695c] px-4 py-2 ml-10 text-white rounded-lg ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              type="submit"
            >
              {loading ? "Uploading..." : "Update details"}
            </button>
          </form>
          <div className="flex flex-col justify-center items-center gap-4 w-6/12">
            <h2 className="text-2xl font-medium">Clients</h2>
            <ul className="flex flex-wrap gap-4 flex-col">
              {currentUsers.map((user) => (
                <li
                  key={user.uid}
                  onClick={() => handleUserClick(user.uid)}
                  className={`text-sm font-medium cursor-pointer p-2 border border-gray-300 rounded flex justify-start items-center w-96 ${
                    user.uid === selectedUserId ? "bg-[#21695c] text-white" : ""
                  }`}
                >
                  <img
                    src={user.photoURL}
                    alt="user"
                    className="w-10 rounded-full mr-10"
                  />
                  <div className="flex flex-col gap-2">
                    <div>{user.name}</div>
                    <div>{user.uid}</div>
                  </div>
                  {/* <button
                  className="bg-red-900 px-4 py-2 ml-10 text-white rounded-lg"
                  onClick={() => handleDelete(user.id)}
                >
                  Delete
                </button> */}
                </li>
              ))}
            </ul>
            <div className="flex justify-center mt-4">
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className={`px-3 py-1 mx-1 border rounded cursor-pointer ${
                  currentPage === 1 ? "bg-gray-200 text-slate-500" : "bg-white"
                }`}
              >
                Previous
              </button>
              <span className="px-3 py-1 mx-1 border rounded bg-white">
                {currentPage} of {totalPages}
              </span>
              <button
                onClick={nextPage}
                disabled={currentPage === totalPages}
                className={`px-3 py-1 mx-1 border rounded cursor-pointer ${
                  currentPage === totalPages
                    ? "bg-gray-200 text-slate-500"
                    : "bg-white"
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <span className="opacity-10">❤️ anup</span>
      </div>
    </>
  );
};

export default AdminClientDetails;
