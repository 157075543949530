import { IoFastFoodSharp } from "react-icons/io5";
import { GiWeightCrush } from "react-icons/gi";
import { FaScaleBalanced } from "react-icons/fa6";
import { MdSupport } from "react-icons/md";

export const aboutCardData = [
  {
    icons: <IoFastFoodSharp />,
    title: "Dietary Consultations",
    bg: "#3AA6B9",
    content:
      "Our team of experienced nutritionists and dietitians offers expert dietary consultations tailored to your specific needs, helping you achieve better health through personalized advice.",
  },
  {
    icons: <GiWeightCrush />,
    title: "Personalized Diets",
    bg: "#AD88C6",
    content:
      "At DietGo, we recognize that one size does not fit all. That's why we create customized diet plans designed to fit your individual health requirements and taste preferences.",
  },
  {
    icons: <FaScaleBalanced />,
    title: "Weight Management",
    bg: "#FF8F00",
    content:
      "We specialize in dietary management for a variety of health conditions including thyroid disorders, PCOD, diabetes, hypertension, and more, offering plans that cater to your unique needs.",
  },
  {
    icons: <MdSupport />,
    title: "Disease Management",
    bg: "#A1DD70",
    content:
      "Our weight management programs are scientifically designed to help you lose weight in a healthy and sustainable way, promoting long-term wellness and effective weight management strategies.",
  },
];
