import React, { useState, useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  // Navigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import PlansPage from "./pages/PlansPage";
import ContactPage from "./pages/ContactPage";
import Dashboard from "./components/Dashboard/Dashboard";
import LogInPage from "./pages/LogInPage";
import {
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  getAuth,
} from "firebase/auth";
import AppointmentPage from "./pages/AppointmentPage";
import AdminClientDetails from "./admin/AdminClientDetails";
import AdminAppointment from "./admin/AdminAppoinment";
import ShimmerUiPage from "./components/ShimmerUI/ShimmerUiPage";
import AddPost from "./admin/AddPost";
import BlogMain from "./components/Blogs/BlogMain";
import BlogSingle from "./components/Blogs/BlogsSingle";
import MainLayout from "./Layout/MainLayout";
import AdminLayout from "./Layout/AdminLayout";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import PrivateRoute from "./PrivateRoutes/index";
import ErrorPage from "./pages/ErrorPage";

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    // Set Firebase Auth to use local persistence
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Listen for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setUser(user);
          setIsLoading(false);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
      })
      .catch((error) => {
        console.error("Failed to set persistence:", error);
        setIsLoading(false);
      });
  }, [auth]);

  if (isLoading) {
    return <ShimmerUiPage />;
  }

  const dashboardRoute = user
    ? `/dashboard/${user.displayName?.toLowerCase().replace(/\s/g, "")}`
    : "/dashboard";

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/about",
          element: <AboutPage />,
        },
        {
          path: "/plans",
          element: <PlansPage />,
        },
        {
          path: "/contact",
          element: <ContactPage />,
        },
        {
          path: "/blogs",
          element: <BlogMain />,
        },
        {
          path: "/blogs/:id",
          element: <BlogSingle />,
        },
        {
          path: "/login",
          element: <LogInPage />,
        },
        {
          path: "/appointment",
          element: <AppointmentPage />,
        },
        {
          path: "/supercontrol/signin",
          element: <SignIn />,
        },
        {
          path: "/supercontrol/signup",
          element: <SignUp />,
        },
        {
          path: dashboardRoute,
          element: <Dashboard />,
        },
      ],
    },
    {
      path: "/supercontrol",
      element: <AdminLayout />,
      children: [
        {
          path: "signin",
          element: <SignIn />,
        },
        {
          path: "appointments",
          element: <PrivateRoute />,
          children: [
            {
              path: "",
              element: <AdminAppointment />,
            },
          ],
        },
        {
          path: "add-blog",
          element: <PrivateRoute />,
          children: [
            {
              path: "",
              element: <AddPost />,
            },
          ],
        },
        {
          path: "client-details",
          element: <PrivateRoute />,
          children: [
            {
              path: "",
              element: <AdminClientDetails />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      // element: <Navigate to="/" />,
      element: <ErrorPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
