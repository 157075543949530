import React from "react";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { RiCrosshair2Fill } from "react-icons/ri";
import bestseller from "../../assets/bestseller.png"

export const PackageCard = ({ plan, elevated }) => (
  <div
    className={`lg:w-1/3 w-full h-full border rounded-xl shadow-lg p-6  text-center transition-transform duration-300  relative font-poppins ${
      elevated
        ? " lg:scale-125 shadow-2xl bg-green-200 border-orange-400 lg:z-10"
        : "lg:hover:scale-125 hover:shadow-xl bg-white hover:bg-green-200 lg:hover:z-20  border-orange-400"
    }`}
  >
    {elevated ? (
      <img
        src={bestseller}
        alt="bestsellter"
        className="w-12 absolute left-5 top-5"
      />
    ) : (
      ""
    )}
    <div className="">
      <h4 className="text-lg font-semibold mb-2">{plan.title}</h4>
      <p className="mb-4 ">{plan.consultations}</p>
      <div className="mb-4">
        <h5 className="font-semibold pb-4 font-montserrat text-orange-500">
          FOCUS
        </h5>
        <ul className="text-start flex flex-col gap-2 text-sm">
          {plan.focus.map((item, index) => (
            <li key={index} className="flex items-center gap-2">
              <span className="text-lg text-orange-500">
                {item.includes("✅") ? <RiCrosshair2Fill /> : <RxCross2 />}
              </span>
              <span className="text-xs font-satoshi">{item.slice(1)}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="mb-4">
        <h5 className="font-semibold pb-4 font-montserrat text-orange-500">
          FEATURES
        </h5>
        <ul className="text-start text-sm flex flex-col gap-2">
          {plan.features.map((item, index) => (
            <li key={index} className="flex items-center gap-2">
              <span className="text-lg text-orange-500">
                {item.includes("✅") ? <RiCrosshair2Fill /> : <RxCross2 />}
              </span>
              <span className="text-xs font-satoshi">{item.slice(1)}</span>{" "}
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4">
        <Link to="/appointment">
          <button
            className="text-sm bg-[#21695c] text-white px-4 py-2 rounded-lg gap-2"
            style={{ fontWeight: "500" }}
          >
            Get Started
          </button>
        </Link>
      </div>
    </div>
  </div>
);
