import React from 'react'
import { ourTeam } from './OurTeamData';

const OurTeam = () => {
  return (
    <div className=" flex flex-col gap-6 text-center">
      <div className="flex flex-col gap-4 pb-4 pt-20 " data-aos="fade-up">
        <h3 className="text-2xl font-bold text-[#2AAA8A] font-montserrat">
          OUR TEAM
        </h3>
        <h4 className="text-secondary text-balance  pb-10">
          Meet the DietGo team, dedicated to transforming your wellness journey
          with personalized nutrition and expert guidance. Together, we strive
          to inspire healthier, happier lives.
        </h4>
      </div>
      <div
        className="w-full flex flex-wrap justify-center items-center lg:gap-10 gap-4 relative"
        data-aos="fade-up"
      >
        {ourTeam.map((member, index) => (
          <a
            key={index}
            href={member.linkedIn}
            target="_blank"
            rel="noreferrer"
            className="lg:static sticky top-20"
          >
            <div
              key={index}
              className={`w-full h-full border rounded-xl shadow-2xl hover:scale-105 transition-all ease-in-out duration-300 flex flex-col lg:flex-row ${
                index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
              } gap-4 justify-center items-center text-center bg-[#002244]`}
            >
              <img
                src={member.img}
                alt={member.name}
                className="w-full lg:w-1/2 h-60 object-cover rounded-t-xl lg:rounded-t-none lg:rounded-l-xl"
              />
              <div className="text-wrap flex flex-col justify-center gap-1 font-montserrat p-4 lg:w-1/2">
                <span className="text-sm font-medium text-white">
                  {member.name}
                </span>
                <span className="text-xs font-normal text-orange-500">
                  {member.role}
                </span>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default OurTeam