import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { CardBody, CardTitle } from "reactstrap";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import app from "../../config/firease";
import ShimmerBlogSection from "../../Shimmer/ShimmerBlogSection";
import { IoMdSearch } from "react-icons/io";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { BiSolidShareAlt } from "react-icons/bi";
import { FaRegCopy } from "react-icons/fa";

const BlogMain = () => {
  const [blogData, setBlogData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [visibleBlogs, setVisibleBlogs] = useState(8);
  const [openShare, setOpenShare] = useState(null);
  const [copiedMessage, setCopiedMessage] = useState({});

  useEffect(() => {
    const fetchBlogData = async () => {
      const db = getFirestore(app);
      const querySnapshot = await getDocs(collection(db, "posts"));
      const blogs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogData(blogs);
      setFilteredData(blogs);
    };

    fetchBlogData();
  }, []);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query) {
        setFilteredData(
          blogData.filter((data) =>
            data.title.toLowerCase().includes(query.toLowerCase())
          )
        );
      } else {
        setFilteredData(blogData);
      }
    }, 300),
    [blogData]
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, debouncedSearch]);

  const handleShareClick = (id) => {
    setOpenShare(openShare === id ? null : id);
  };

  const handleShareMouseLeave = () => {
    setOpenShare(null);
  };

  const handleCopyLink = (url, id) => {
    navigator.clipboard.writeText(url).then(() => {
      setCopiedMessage({ ...copiedMessage, [id]: true });
      setOpenShare(null);
      setTimeout(
        () => setCopiedMessage({ ...copiedMessage, [id]: false }),
        600
      );
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + document.documentElement.scrollTop) <=
        100
      ) {
        console.log("Fetching more blogs...");
        setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 4);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-home">
      <div className="lg:py-32 page-padding py-20">
        <div className="flex lg:flex-row flex-col justify-between gap-4 text-center pb-20">
          <div>
            <h2
              className="text-2xl font-bold text-[#2AAA8A] font-montserrat"
              data-aos="fade-down"
            >
              DIETGO BLOGS
            </h2>
          </div>
          <div className="flex flex-row gap-1 items-center">
            <IoMdSearch />
            <input
              type="text"
              placeholder="Search"
              className="outline-none bg-transparent"
              onChange={handleSearch}
            />
          </div>
        </div>

        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full"
          data-aos="fade-up"
        >
          {filteredData.length === 0 ? (
            <ShimmerBlogSection />
          ) : filteredData.length > 0 ? (
            filteredData.slice(0, visibleBlogs).map((data) => {
              const fullUrl = `${window.location.origin}/blogs/${data.id}`;
              return (
                <div
                  key={data.id}
                  className="flex flex-col gap-4 h-full border rounded-xl shadow-xl bg-white"
                  onMouseLeave={() => handleShareMouseLeave()}
                >
                  <Link to={`/blogs/${data.id}`}>
                    {data.imageUrl && (
                      <img
                        src={data.imageUrl}
                        alt={data.title}
                        className="w-full h-40 object-cover rounded-t-xl"
                      />
                    )}
                  </Link>
                  <CardBody className="pt-0 text-start p-2">
                    <Link to={`/blogs/${data.id}`}>
                      <CardTitle
                        tag="h5"
                        className="font-bold font-montserrat mb-2"
                      >
                        {data.title}
                      </CardTitle>
                    </Link>
                    <Link to={`/blogs/${data.id}`}>
                      {" "}
                      <span
                        className="text-sm two-lines pb-2 font-montserrat text-gray-500"
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      ></span>
                    </Link>
                    <div className="relative flex justify-between items-center">
                      <button
                        onMouseEnter={() => handleShareClick(data.id)}
                        className="text-[#2AAA8A] hover:text-orange-700 text-2xl p-2"
                      >
                        <BiSolidShareAlt />
                      </button>
                      <Link to={`/blogs/${data.id}`}>
                        <span className="text-sm text-[#2AAA8A] p-2 hover:text-[#2c7a67]">
                          Read more
                        </span>
                      </Link>
                      {openShare === data.id && (
                        <div className="absolute top-4 bg-white border rounded-lg shadow-lg p-2 z-10 flex gap-2">
                          <FacebookShareButton url={fullUrl}>
                            <FacebookIcon size={24} round />
                          </FacebookShareButton>
                          <WhatsappShareButton url={fullUrl}>
                            <WhatsappIcon size={24} round />
                          </WhatsappShareButton>
                          <LinkedinShareButton url={fullUrl}>
                            <LinkedinIcon size={24} round />
                          </LinkedinShareButton>
                          <TwitterShareButton url={fullUrl}>
                            <TwitterIcon size={24} round />
                          </TwitterShareButton>
                          <button
                            onClick={() => handleCopyLink(fullUrl, data.id)}
                            className="flex items-center justify-center w-6 text-white bg-gray-500 rounded-full hover:bg-gray-700"
                          >
                            <FaRegCopy />
                          </button>
                        </div>
                      )}
                      {copiedMessage[data.id] && (
                        <div className="absolute top-0 right-0 bg-green-500 text-white text-xs p-1 rounded">
                          Copied!
                        </div>
                      )}
                    </div>
                  </CardBody>
                </div>
              );
            })
          ) : (
            <div>No blogs found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogMain;
