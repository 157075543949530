import React from "react";
// import Package from "../components/plans/Package";
import Plans from "../components/plans/Plans";

export default function PlansPage() {
  return (
    <div className="relative">
      <div className="bg-home page-padding">
        {/* <Plans /> */}
        <Plans />
      </div>
    </div>
  );
}
