import React from "react";
import Contact from "../components/contact/Contact";

export default function ContactPage() {
  return (
    <>
      <div className="relative bg-home pt-20 h-screen page-padding text-center">
        <Contact />
      </div>
      <div className="fixed bottom-0 w-full"></div>
    </>
  );
}
