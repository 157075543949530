import React from "react";
// import DashNavbar from "./DashNavbar";
import DashProfile from "./DashProfile";

export default function Dashboard() {
  return (
    <div>
      {/* <DashNavbar /> */}
      <DashProfile />
    </div>
  );
}
