import React from "react";
import leadImage1 from "../../assets/value-1-en.webp";
import leadImage2 from "../../assets/value-2.webp";
import leadImage3 from "../../assets/value-3-en.webp";

function Feature() {
  return (
    <div className="py-16 font-montserrat">
      <div className="flex flex-col justify-center items-center lg:px-4 py-2 gap-6">
        <h2 className="text-2xl font-bold text-[#2AAA8A]" data-aos="fade-up">
          WE GOT YOUR BACK !
        </h2>
        <p className="text-xl font-medium text-secondary" data-aos="fade-up">
          You tell us your goals. We’ll help you to get there.
        </p>

        <div
          className="flex flex-col-reverse lg:gap-0 gap-10 justify-between lg:flex-row items-center mt-10"
          data-aos="fade-right"
        >
          <div className="lg:w-5/12 w-full flex flex-col gap-4">
            <h2 className="font-normal leading-6  text-xl">
              Personalization means
              <span className="text-secondary"> made for you.</span>
            </h2>
            <p className=" text-lg-start text-center lg:text-balance">
              We’ll guide you to the delicious foods that will help you meet
              your goals. Whether you’re trying to manage type 2 diabetes or
              looking for help with weight loss, we can guide you toward the
              meals that make success easier.
            </p>
          </div>
          <div className="lg:w-5/12 w-full flex justify-end items-center">
            <img className="w-full" src={leadImage1} alt="" />
          </div>
        </div>

        <div
          className="flex flex-col lg:gap-0 gap-10 justify-between lg:flex-row items-center mt-10"
          data-aos="fade-left"
        >
          <div className="lg:w-5/12 w-full flex flex-col gap-4">
            <h2 className="font-normal leading-6  text-xl">
              Enjoy life without
              <span className="text-secondary">
                - hunger, cravings & struggle.
              </span>
            </h2>
            <p className="text-lg-start text-center text-balance ">
              When you find the food that’s right for you, everything gets
              easier. Say goodbye to hunger, and welcome a quieter feeling:
              satisfaction. Many members say their cravings fell away when they
              stopped dieting and started eating better.
            </p>
          </div>
          <div className="lg:w-6/12 w-full order-first lg:order-last mt-6 lg:mt-0 flex justify-start items-center">
            <img className="w-full" src={leadImage2} alt="" />
          </div>
        </div>

        <div
          className="flex flex-col-reverse lg:gap-0 gap-10 justify-between lg:flex-row items-center mt-10"
          data-aos="fade-right"
        >
          <div className="lg:w-5/12 w-full flex flex-col gap-4">
            <h2 className="font-normal leading-6  text-xl">
              Stay confident —
              <span className="text-secondary">you’re on the right track.</span>
            </h2>
            <p className="text-lg-start text-center text-balance">
              Our guidance will help you visualize your journey to better health
              with daily tips, tracking, recipes, and education. Follow along
              through our expert-led practical guides about anything and
              everything from budgeting, to navigating social situations, and
              much more!
            </p>
          </div>
          <div className="lg:w-6/12 w-full mt-6 lg:mt-0 flex justify-end items-center">
            <img className="w-full" src={leadImage3} alt="" />
          </div>
        </div>
        <hr className="featurette-divider" />
      </div>
    </div>
  );
}

export default Feature;
