import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../config/firease";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { admin4, admin5 } from "../constants";
import logo from "../assets/logo-mod.png";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = getAuth();
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    const log = signInWithEmailAndPassword(auth, email, password) // eslint-disable-line no-unused-vars
      .then((userCredentails) => {
        const user = userCredentails.user;

        toast.success("Welcome admin");
        if (user.email === admin4 || admin5) {
          toast.success("Welcome admin");

          setEmail("");
          setPassword("");
          setTimeout(() => {
            navigate("/supercontrol/add-blog");
          }, 300);
        } else {
          toast.error("Invalid credentials");
        }
      })
      .catch((err) => {
        const errorMessage = err.message;
        console.error("error is ", errorMessage);
        toast.error("Invalid Credentials", errorMessage);
      });
  };

  return (
    <>
      <div className="container-fluid admin-login-container d-flex align-items-center justify-content-center m-0 h-screen">
        <div className="content flex flex-col justify-center items-center">
          <div className="text-center flex flex-col justify-center items-center gap-2">
            <img src={logo} alt="dietgo-logo" className="w-40" />
            <h1 className="text-xl font-montserrat mb-10 sign_in_title">
              Sign in to Admin Panel
            </h1>
          </div>
          <form
            className="flex flex-col justify-center items-center gap-4 "
            id="login"
            onSubmit={handleLogin}
          >
            <div className="content__inputs flex flex-col gap-4  text-black">
              {/* <label htmlFor="email">
                Email <span>*</span>
              </label> */}
              <input
                required
                type="text"
                placeholder="xyz@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-96 rounded-xl p-2 outline-none border-b-2 "
              />
              {/* <label htmlFor="email">
                Password <span>*</span>
              </label> */}
              <input
                required
                type="password"
                placeholder="xyz"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-96 rounded-xl p-2 outline-none border-b-2 "
              />
            </div>
            <button
              type="submit"
              className="text-center text-sm font-montserrat font-medium justify-center flex items-center gap-2 rounded-xl lg:py-4 lg:px-8 px-4 py-2 bg-[#FF4D30] text-white shadow-custom hover:shadow-custom-hovered transition-all duration-300 ease-in-out button w-40 mt-4"
            >
              Log In
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignIn;
