import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../config/firease"; // Import your Firebase configuration
import AdminNavbar from "../Layout/AdminNavbar";

const AdminAppointment = () => {
  const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const appointmentsPerPage = 5;

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const appointmentsCollection = collection(db, "appointments");
        const appointmentsSnapshot = await getDocs(appointmentsCollection);

        const appointmentsData = appointmentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        setAppointments(appointmentsData);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };

    fetchAppointments();
  }, []);

  const handleDoneClick = async (id) => {
    try {
      const appointmentRef = doc(db, "appointments", id);
      await updateDoc(appointmentRef, { done: true });
      updateAppointments(id, { done: true });
    } catch (error) {
      console.error("Error marking appointment as done:", error);
    }
  };

  const handleUndoneClick = async (id) => {
    try {
      const appointmentRef = doc(db, "appointments", id);
      await updateDoc(appointmentRef, { done: false });
      updateAppointments(id, { done: false });
    } catch (error) {
      console.error("Error marking appointment as undone:", error);
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      const appointmentRef = doc(db, "appointments", id);
      await deleteDoc(appointmentRef);
      setAppointments((prevAppointments) =>
        prevAppointments.filter((appointment) => appointment.id !== id)
      );
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  };

  const updateAppointments = (id, updatedFields) => {
    setAppointments((prevAppointments) =>
      prevAppointments.map((appointment) =>
        appointment.id === id
          ? { ...appointment, data: { ...appointment.data, ...updatedFields } }
          : appointment
      )
    );
  };

  // Pagination logic
  const indexOfLastAppointment = currentPage * appointmentsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
  const currentAppointments = appointments.slice(
    indexOfFirstAppointment,
    indexOfLastAppointment
  );

  const totalPages = Math.ceil(appointments.length / appointmentsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="">
      <AdminNavbar />
      <div className="pt-20 flex flex-col justify-center items-center bg-home h-full">
        <h2 className="text-heading text-3xl font-bold"> Appointments</h2>
        <table className="flex flex-col mt-10 mx-10 text-center shadow-md rounded-md overflow-hidden border border-black w-[80%]">
          <thead>
            <tr className="grid grid-cols-7 text-center p-4 bg-[#21695c] text-white">
              <th className="">Name</th>
              <th className="">Age</th>
              <th className="">Gender</th>
              <th className="">Contact Number</th>
              <th className="">Date</th>
              <th className="">Time</th>
              <th className="">Action</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {currentAppointments.map((appointment) => (
              <tr
                key={appointment.id}
                style={{
                  textDecoration: appointment.data.done
                    ? "line-through"
                    : "none",
                }}
                className="grid grid-cols-7 py-2 border-b border-slate-500"
              >
                <td className="text-center pt-2">{appointment.data.name}</td>
                <td className="text-center pt-2">{appointment.data.age}</td>
                <td className="text-center pt-2">{appointment.data.gender}</td>
                <td className="text-center pt-2">
                  {appointment.data.contactNumber}
                </td>
                <td className="text-center pt-2">{appointment.data.date}</td>
                <td className="text-center pt-2">{appointment.data.time}</td>
                <td>
                  {appointment.data.done ? (
                    <button
                      className="p-1 rounded-lg text-sm bg-[#21695c] text-white"
                      onClick={() => handleUndoneClick(appointment.id)}
                    >
                      Undone
                    </button>
                  ) : (
                    <button
                      className="px-1 rounded-lg text-sm bg-[#21695c] text-white"
                      onClick={() => handleDoneClick(appointment.id)}
                    >
                      Done
                    </button>
                  )}
                  <button
                    className="px-1  rounded-lg text-sm bg-red-700 text-white"
                    onClick={() => handleDeleteClick(appointment.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          <button
            onClick={prevPage}
            disabled={currentPage === 1}
            className={`px-3 py-1 mx-1 border rounded cursor-pointer ${
              currentPage === 1 ? "bg-gray-200 text-slate-500" : "bg-white"
            }`}
          >
            Previous
          </button>
          <span className="px-3 py-1 mx-1 border rounded bg-white">
            {currentPage} of {totalPages}
          </span>
          <button
            onClick={nextPage}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 mx-1 border rounded cursor-pointer ${
              currentPage === totalPages ? "bg-gray-200 text-slate-500" : "bg-white"
            }`}
          >
            Next
          </button>
        </div>
        <span className="opacity-10 pt-80">Love you Arpita, my love</span>
      </div>
    </div>
  );
};

export default AdminAppointment;
