

export const plans1 = [
  {
    type: "WEIGHT MANAGEMENT",
    title: "1 MONTH",
    consultations: "4 DIETITIAN CONSULTATIONS",
    focus: [
      "WATER WEIGHT",
      "METABOLISM BOOSTING",
      " WEIGHT FLEXIBILITY",
      "SKIN & HAIR",
      "BODY DETOX",
      //   "LIFESTYLE GUIDANCE",
    ],
    features: [
      "DEDICATED DIET COACH",
      "YOGA ADVICES",
      "8-HOURS DEDICATED DIET COACH SUPPORT",
      "CUSTOMISED DIET PLAN",
      "SKIN AND HAIR CARE DIET",
      "PAUSE FACILITY",
    ],
    price: "₹2,500",
  },
  {
    type: "WEIGHT MANAGEMENT",
    title: "3 MONTHS",
    consultations: "12 DIETITIAN CONSULTATIONS",
    focus: [
      " WEIGHT FLEXIBILITY",
      "METABOLISM BOOSTING",
      "SKIN & HAIR",
      "BODY DETOX",
      "LIFESTYLE GUIDANCE",
    ],
    features: [
      "DEDICATED DIET COACH",
      "YOGA ADVICES",
      "8-HOURS DEDICATED DIET COACH SUPPORT",
      "CUSTOMISED DIET PLAN",
      "SKIN AND HAIR CARE DIET",
      "PAUSE FACILITY",
    ],
    price: "₹5,500",
  },
  {
    type: "WEIGHT MANAGEMENT",
    title: "6 MONTHS",
    consultations: "24 DIETITIAN CONSULTATIONS",
    focus: [
      " WEIGHT FLEXIBILITY",
      "METABOLISM BOOSTING",
      "SKIN & HAIR",
      "BODY DETOX",
      "LIFESTYLE GUIDANCE",
    ],
    features: [
      "DEDICATED DIET COACH",
      "YOGA ADVICES",
      "8-HOURS DEDICATED DIET COACH SUPPORT",
      "CUSTOMISED DIET PLAN",
      "SKIN AND HAIR CARE DIET",
      "PAUSE FACILITY",
    ],
    price: "₹9,500",
  },
];

export const plans2 = [
  {
    type: "WEIGHT MANAGEMENT PLANS",
    title: "DietGo Plan - 1 MONTH",
    consultations: "4 DIETITIAN CONSULTATIONS",
    focus: ["WATER WEIGHT", "METABOLISM BOOSTING"],
    features: [
      "DEDICATED DIET COACH",
      "8-HOURS DEDICATED DIET COACH SUPPORT",
      "CUSTOMISED DIET PLAN",
      "SKIN AND HAIR CARE DIET",
    ],
    price: "₹2,500",
  },
  {
    type: "WEIGHT MANAGEMENT PLANS",
    title: "DietGo Plan - 3 MONTHS",
    consultations: "12 DIETITIAN CONSULTATIONS",
    focus: [
      " WEIGHT FLEXIBILITY",
      "METABOLISM BOOSTING",
      "SKIN & HAIR",
      "BODY DETOX",
    ],
    features: [
      "DEDICATED DIET COACH",
      "8-HOURS DEDICATED DIET COACH SUPPORT",
      "CUSTOMISED DIET PLAN",
      "SKIN AND HAIR CARE DIET",
      "PAUSE FACILITY",
    ],
    price: "₹5,500",
  },
  {
    type: "WEIGHT MANAGEMENT PLANS",
    title: "DietGo Plan - 6 MONTHS",
    consultations: "24 DIETITIAN CONSULTATIONS",
    focus: [
      " WEIGHT FLEXIBILITY",
      "METABOLISM BOOSTING",
      "SKIN & HAIR",
      "BODY DETOX",
      "LIFESTYLE GUIDANCE",
    ],
    features: [
      "DEDICATED DIET COACH",
      "YOGA ADVICES",
      "8-HOURS DEDICATED DIET COACH SUPPORT",
      "CUSTOMISED DIET PLAN",
      "SKIN AND HAIR CARE DIET",
      "PAUSE FACILITY",
    ],
    price: "₹9,500",
  },
];

function comparePlans(plans1, plans2) {
  function markAttributes(attributes1, attributes2) {
    const markedAttributes = attributes1.map((item) => {
      const included = attributes2.includes(item);
      return `${
        included ? (
          "✅"
        ) : (
         "❎"
        )
      } ${item}`;
    });
    return markedAttributes;
  }

  const result = plans1
    .map((plan1) => {
      const plan2 = plans2.find(
        (p) =>
          p.title.toUpperCase().includes(plan1.title.toUpperCase()) &&
          p.type.toUpperCase().includes(plan1.type.toUpperCase())
      );

      if (!plan2) return null;

      const focusMarked = markAttributes(plan1.focus, plan2.focus);
      const featuresMarked = markAttributes(plan1.features, plan2.features);

      return {
        ...plan1,
        focus: focusMarked,
        features: featuresMarked,
      };
    })
    .filter((plan) => plan !== null);

  return result;
}

export const comparedPlans = comparePlans(plans1, plans2);
