import React, { useState } from "react";
import "./BmiCalculator.css";
import smileCat from "../../assets/smile-cat.gif";
import sadCat from "../../assets/sad-cat.gif";
import { IoIosMale, IoIosFemale } from "react-icons/io";
import { IoFemaleOutline } from "react-icons/io5";

export default function BmiCalculator() {
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [age, setAge] = useState("");
  const [disease, setDisease] = useState("Choose an option");
  const [bmiResult, setBmiResult] = useState(null);

  const [selectedDiv, setSelectedDiv] = useState(null);

  const toggleBorder = (divId) => {
    setSelectedDiv((prevSelectedDiv) =>
      prevSelectedDiv === divId ? null : divId
    );
  };

  const calculateBmi = (event) => {
    event.preventDefault();

    document.body.style.position = "relative";
    const overlay = document.createElement("div");
    overlay.id = "overlay";
    overlay.style.position = "fixed";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.width = "100%";
    overlay.style.height = "100%";
    overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    overlay.style.zIndex = "9";
    document.body.appendChild(overlay);

    if (!height || !weight || !age || disease === "Choose an option") {
      alert("Please fill in all fields");
      return;
    }

    // Calculate BMI
    const heightInMeters = height / 100;
    const bmi = weight / heightInMeters ** 2;
    console.log(bmi);

    // Set BMI result
    setBmiResult(bmi);
  };

  function handleClose() {
    setBmiResult(!bmiResult);
    const overlayElement = document.getElementById("overlay");
    if (overlayElement) {
      overlayElement.parentNode.removeChild(overlayElement);
    }
  }

  return (
    <div className="bmi w-full py-20  flex flex-col justify-center items-center flex-wrap gap-4 relative font-poppins">
      <h2
        data-aos="fade-right"
        className="text-2xl font-bold  text-[#2AAA8A]"
      >
          GET DEEPER INSIGHTS OF YOUR HEALTH
      </h2>
      <p className="text-xl font-medium text-secondary pb-12">
        Use our BMI calculator to understand where you are in your fitness
        journey
      </p>
      <h4
        data-aos="fade-left"
        className="px-4 py-2 rounded bg-[#21695c] text-white mb-6"
      >
        BMI Calculator
      </h4>
      <div
        className="flex justify-center items-center gap-4 text-center z-[4] flex-wrap pb-6"
        data-aos="fade-right"
      >
        <div
          id="male"
          onClick={() => toggleBorder("male")}
          className={`w-20 flex flex-col justify-center items-center text-sm cursor-pointer p-2 bg-white rounded-lg gap-2 ${
            selectedDiv === "male" ? "selected" : ""
          }`}
        >
          {" "}
          <span>
            <IoIosMale />
          </span>
          <p>Male</p>
        </div>
        <div
          id="female"
          onClick={() => toggleBorder("female")}
          className={`w-20 flex flex-col justify-center items-center text-sm cursor-pointer p-2 bg-white rounded-lg gap-2 ${
            selectedDiv === "female" ? "selected" : ""
          }`}
        >
          <span>
            <IoFemaleOutline />
          </span>
          <p>Female</p>
        </div>
        <div
          id="other"
          onClick={() => toggleBorder("other")}
          className={`w-20 flex flex-col justify-center items-center text-sm cursor-pointer p-2 bg-white rounded-lg gap-2 ${
            selectedDiv === "other" ? "selected" : ""
          }`}
        >
          <span>
            <IoIosFemale />
          </span>
          <p>Other</p>
        </div>
      </div>

      <form
        className="bmi-form w-[50vw] flex justify-center items-center flex-wrap gap-8 py-16 shadow-sm bg-white rounded-lg z-[4]"
        onSubmit={calculateBmi}
        data-aos="fade-left"
      >
        <div className="flex flex-col pb-5 gap-1">
          <label htmlFor="height">Height</label>
          <input
            id="height"
            type="number"
            placeholder="Height in cm"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col pb-5 gap-1">
          <label htmlFor="weight">Weight</label>
          <input
            id="weight"
            type="number"
            placeholder="Weight in Kgs"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col pb-5 gap-1">
          <label htmlFor="age">Age</label>
          <input
            id="age"
            type="number"
            placeholder="Age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col pb-5 gap-1">
          <label htmlFor="disease">Disease</label>
          <select
            id="disease"
            value={disease}
            onChange={(e) => setDisease(e.target.value)}
          >
            <option value="Choose an option">Choose an option</option>
            <option value="PCOD">PCOD</option>
            <option value="Thyroid">Thyroid</option>
            <option value="Diabetes">Diabetes</option>
            <option value="None">None</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div>
          <button type="submit" className="cal-btn">
            Calculate
          </button>
        </div>
      </form>

      {bmiResult && (
        <div className="bmi-result" data-aos="fade-left">
          <button
            className="hover:bg-[#21695c] hover:text-white"
            onClick={handleClose}
          >
            Recalculate BMI
          </button>
          <div className="bmi-status-div">
            {" "}
            <div className="bmi-status">
              {bmiResult > 30 ? (
                <div>
                  {/* <span className="bmi-status-icon"> <SlEmotsmile /></span> */}
                  <img className="bmi-status-icon" src={sadCat} alt="" />
                  <p>
                    Your BMI is considered <span>Obese</span>
                  </p>
                </div>
              ) : bmiResult >= 25 ? (
                <div>
                  <img className="bmi-status-icon" src={sadCat} alt="" />
                  <p>
                    Your BMI is considered <span>Overweight</span>
                  </p>
                </div>
              ) : bmiResult >= 18.5 ? (
                <div>
                  <img className="bmi-status-icon" src={smileCat} alt="" />
                  <p>
                    Your BMI is considered <span>Healthy</span>
                  </p>
                </div>
              ) : (
                <div>
                  <img className="bmi-status-icon" src={sadCat} alt="" />
                  <p>
                    Your BMI is considered <span>Underweight</span>
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="bmi-index">
            <div className={bmiResult <= 18.5 ? "underweight" : ""}>
              <span>Underweight</span>
              <p>Below 18.5</p>
            </div>
            <div
              className={
                bmiResult >= 18.5 && bmiResult <= 24.9 ? "healthy" : ""
              }
            >
              <span>Healthy</span>
              <p>18.5 — 24.9</p>
            </div>
            <div
              className={
                bmiResult >= 25 && bmiResult <= 29.9 ? "overweight" : ""
              }
            >
              <span>Overweight</span>
              <p>25.0 — 29.9</p>
            </div>
            <div className={bmiResult >= 30 ? "obese" : ""}>
              <span>Obese</span>
              <p>30 & above</p>
            </div>
          </div>

          <p className="bmiResult">
            Your BMI is{" "}
            <span style={{ color: "#dc3545" }}>{bmiResult.toFixed(2)}</span>
          </p>
        </div>
      )}
    </div>
  );
}
