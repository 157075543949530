import { FaWhatsapp } from "react-icons/fa";
export default function NotProUser() {
  return (
    <div className="flex flex-col gap-4">
      <p className="">You have no active plans!</p>
      <h3 className="text-sm font-thin text-[#333333]">
        Redefine fitness with DietGo: Expert guidance from the Coaches
      </h3>

      <button
        className="lg:text-sm bg-[#21695c] text-white px-4 py-2 rounded-lg  lg:w-auto w-full"
        style={{ fontWeight: "500" }}
      >
        {" "}
        <a
          href="https://wa.me/916296709257"
          target="_blank"
          rel="noreferrer"
          className="flex justify-center items-center gap-2"
        >
          <span className="text-[16px]">
            <FaWhatsapp />
          </span>{" "}
          <span className="font-extralight text-xs">WhatsApp Us</span>{" "}
        </a>
      </button>
    </div>
  );
}
