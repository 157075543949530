import React from "react";

export default function ShimmerUi() {
  return (
    <div className="flex flex-col gap-4">
      <div className="bg-zinc-200 rounded-lg w-40 h-2"></div>
      <div className="bg-zinc-200 rounded-lg w-20 h-4"></div>
      <div className="bg-zinc-200 rounded-lg w-40 h-2"></div>
      <div className="bg-zinc-200 rounded-lg w-80 h-8"></div>
    </div>
  );
}
