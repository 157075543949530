import React from "react";
import sadCat from "../assets/sad-cat.gif";

const ErrorPage = () => {
  return (
    <div className="w-full h-screen flex flex-col gap-4 justify-center items-center">
      <img className="w-40" src={sadCat} alt="sad-cat" />

      <div className="flex flex-col gap-2 text-center">
        <h1 className="text-red-700">404 Not Found</h1>
        <p>Oops! The page you're looking for doesn't exist.</p>
      </div>
    </div>
  );
};

export default ErrorPage;
