import React from "react";
import hero from "../../assets/hero.png";
import { IoIosArrowDropright } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="relative font-poppins lg:h-screen z-[14] lg:pt-0 pt-32">
      <div className="  " id="home">
        <div className="flex flex-col-reverse gap-16 lg:flex-row-reverse  lg:gap-0 items-center justify-center lg:justify-between lg:py-5">
          <div className="lg:w-6/12 lg:pt-10 z-1">
            <img
              src={hero}
              className=""
              alt="home"
              // loading="lazy"
              data-aos="fade-left"
            />
          </div>
          <div className="lg:w-6/12 lg:block flex flex-col gap-6">
            <h1
              className="lg:text-[2.5rem] text-[1.8rem] font-[600] mb-3 text-heading"
              style={{ lineHeight: "1.1" }}
              data-aos="fade-right"
            >
              Now Get Fit By Your Choice with{" "}
              <span className="text-[#29b952]">Home Foods !</span>
            </h1>
            <p className="font-medium text-secondary" data-aos="fade-right">
              Start your journey with us today with small changes!
            </p>
            <div
              className="btn-div pt-4 flex lg:justify-start justify-center flex-wrap gap-4"
              data-aos="fade-up"
            >
              <a
                href="https://wa.me/916296709257"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <button
                  className="text-sm bg-[#21695c] text-white px-4 py-2 flex justify-center items-center rounded-lg gap-2"
                  style={{ fontWeight: "500" }}
                >
                  <span>
                    <FaWhatsapp />
                  </span>{" "}
                  <span>WhatsApp Us</span>
                </button>
              </a>
              <Link to="/appointment">
                <button
                  className="text-sm bg-transparent px-4 py-2 flex justify-center items-center rounded-lg gap-2"
                  style={{ border: "1px solid #21635c", fontWeight: "500" }}
                >
                  <span>Get Appointment</span>
                  <span>
                    <IoIosArrowDropright />
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
