import React from "react";
// import About from "../components/about/About";
import AboutUs from "../components/about/AboutUs";

export default function AboutPage() {
  return (
    <>
      
      <div className="relative ">
        <AboutUs />
        
      </div>
    </>
  );
}
