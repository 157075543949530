import React from "react";
import Package from "./Package";
import { MdAttachMoney, MdHealthAndSafety } from "react-icons/md";
import { FaBalanceScale, FaUserAlt } from "react-icons/fa";
import plansImage from "../../assets/plans4-1.jpg";

const Plans = () => {
  return (
    <div className="bg-home text-lg-start text-center">
      <div className="lg:py-32 py-20">
        <div className="flex lg:flex-row flex-col justify-between gap-4 pb-20">
          {/* ----------------------- upper section starts------------------------- */}

          <div className="flex lg:flex-row-reverse flex-col gap-10 lg:justify-between justify-center items-center lg:items-start">
            <div className="flex flex-col gap-6 lg:w-1/2 w-full">
              <h2
                className="text-2xl font-bold text-[#2AAA8A] font-montserrat"
                data-aos="fade-down"
              >
                DIETGO PLANS
              </h2>
              <div className="font-montserrat">
                <h2 className="text-2xl font-semibold text-orange-500">
                  DietGo
                </h2>
                <h3 className="text-secondary">
                  Tailored Solutions for Your Health Goals
                </h3>
              </div>

              <p className="font-poppins text-balance text-secondary text-gray-700 py-2">
                At <strong>DietGo</strong>, we offer a variety of plans to meet
                your unique health needs. Whether you're looking to lose weight,
                manage a health condition, or simply maintain a balanced diet,
                we have a plan for you.
              </p>

              <ul className="flex flex-col gap-3">
                <li
                  className="flex flex-row gap-4 items-center p-2 border bg-white rounded-lg shadow-lg"
                  data-aos="fade-up"
                >
                  <span className="text-4xl text-[#3AA6B9]">
                    <MdAttachMoney />
                  </span>
                  <span className="font-montserrat font-light ">
                    Affordable Weight Loss
                  </span>
                </li>
                <li
                  className="flex flex-row gap-4 items-center p-2 border bg-white rounded-lg shadow-lg"
                  data-aos="fade-up"
                >
                  <span className="text-4xl text-[#AF47D2]">
                    <MdHealthAndSafety />
                  </span>
                  <span className="font-montserrat font-light ">
                    Comprehensive Care
                  </span>
                </li>
                <li
                  className="flex flex-row gap-4 items-center p-2 border bg-white rounded-lg shadow-lg"
                  data-aos="fade-up"
                >
                  <span className="text-4xl text-[#FF8F00]">
                    <FaBalanceScale />
                  </span>
                  <span className="font-montserrat font-light ">
                    Balanced & Simple
                  </span>
                </li>
                <li
                  className="flex flex-row gap-4 items-center p-2 border bg-white rounded-lg shadow-lg"
                  data-aos="fade-up"
                >
                  <span className="text-4xl text-[#A1DD70]">
                    <FaUserAlt />
                  </span>
                  <span className="font-montserrat font-light ">
                    Fully Customized
                  </span>
                </li>
              </ul>
            </div>
            <div className="lg:w-1/2 w-full flex items-center justify-center lg:justify-start lg:items-start">
              <img
                src={plansImage}
                alt="healthy-food"
                className=" lg:w-[80%] w-full shadow-custom object-cover rounded-lg border-white border-[10px]"
              />
            </div>
          </div>

          {/* ----------------------- upper section ends------------------------- */}
        </div>
        <Package />
      </div>
    </div>
  );
};

export default Plans;
