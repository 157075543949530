import React from "react";
import aboutDiet from "../../assets/about-girl.png";
import { IoFastFoodSharp } from "react-icons/io5";
// import { FaPersonRunning } from "react-icons/fa6";
import { GiWeightCrush } from "react-icons/gi";
import { FaScaleBalanced } from "react-icons/fa6";
import { MdSupport } from "react-icons/md";
import WhatWeOffer from "./WhatWeOffer";
import OurTeam from "./OurTeam";

const AboutUs = () => {
  return (
    <div className="bg-home text-lg-start text-center">
      <div className="lg:py-32 page-padding py-20">
        <div className="flex lg:flex-row flex-col justify-between gap-4  pb-20">
          {/* ----------------------- upper section starts------------------------- */}

          <div className="flex lg:flex-row flex-col gap-4 lg:gap-0 lg:justify-between justify-center items-center lg:items-end">
            <div className="flex flex-col gap-4 lg:w-1/2 w-full">
              <h2
                className="text-2xl font-bold text-[#2AAA8A] font-montserrat"
                data-aos="fade-down"
              >
                ABOUT US
              </h2>
              <div className="font-montserrat">
                <h2 className="text-2xl font-semibold text-orange-500">
                  DietGo
                </h2>
                <h3 className="text-secondary">
                  An One Stop Solution for Your Health
                </h3>
              </div>

              <p className="font-poppins text-balance text-secondary py-3">
                Welcome to <strong>DietGo</strong>- your trusted partner in
                achieving holistic health and well-being through expert dietary
                consultations, personalized diet plans, and comprehensive
                disease management.
              </p>
              {/* <p className="font-poppins text-balance">
                At DietGo, we are committed to delivering high-quality,
                evidence-based dietary advice and support. Our goal is to make a
                positive impact on your health and well-being by providing you
                with the knowledge and tools you need to lead a healthier life.
              </p> */}
              <ul className="flex flex-col gap-3">
                <li
                  className="flex flex-row gap-4 items-center p-2 border bg-white rounded-lg shadow-lg"
                  data-aos="fade-up"
                >
                  <span className="text-4xl text-[#3AA6B9]">
                    <IoFastFoodSharp />
                  </span>
                  <span className="font-montserrat font-light ">
                    Personalized Diet Plans
                  </span>
                </li>
                <li
                  className="flex flex-row gap-4 items-center p-2 border bg-white rounded-lg shadow-lg"
                  data-aos="fade-up"
                >
                  <span className="text-4xl text-[#AF47D2]">
                    <GiWeightCrush />
                  </span>
                  <span className="font-montserrat font-light ">
                    Comprehensive Disease Management
                  </span>
                </li>
                <li
                  className="flex flex-row gap-4 items-center p-2 border bg-white rounded-lg shadow-lg"
                  data-aos="fade-up"
                >
                  <span className="text-4xl text-[#FF8F00]">
                    <FaScaleBalanced />
                  </span>
                  <span className="font-montserrat font-light ">
                    Well-Balanced Diet Philosophy
                  </span>
                </li>
                <li
                  className="flex flex-row gap-4 items-center p-2 border bg-white rounded-lg shadow-lg"
                  data-aos="fade-up"
                >
                  <span className="text-4xl text-[#A1DD70]">
                    <MdSupport />
                  </span>
                  <span className="font-montserrat font-light ">
                    Dedicated Support & Consultations
                  </span>
                </li>
              </ul>
            </div>
            <div className="lg:w-1/2 w-full flex items-center justify-center">
              <div className="relative image-container">
                <img src={aboutDiet} alt="diet-plate" className=" w-full" />
                <div className="fade-overlay"></div>
              </div>
            </div>
          </div>

          {/* ----------------------- upper section ends------------------------- */}
        </div>
        <WhatWeOffer />
        <OurTeam />
      </div>
    </div>
  );
};

export default AboutUs;
